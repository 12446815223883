import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const PatentFaqs = () => {
    const seo = {
        metaDesc: 'Supporting inventors world-wide - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Supporting inventors world-wide - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <p class="f-16"> How to Protect Your Invention with the Patent<b> system</b></p>
                    <h3>Get <a href="provisional-builder" class="d-clr">free software to draft high quality provisional</a>
                        patent applications and begin the protection of your invention today.</h3>
                    <h3>Basics of intellectual property (IP): Patents, Trademarks, Copyrights, and Trade Secrets.</h3>
                    <p class="f-16">A patent is a form of IP protection that gives the holder exclusive rights to use, make, and
                        sell an invention for a certain period of time. In order to be eligible for a patent, an invention must
                        be new, useful, and non-obvious.</p>
                    <p class="f-16">The process for applying for a patent application can vary depending on the type of patent
                        and the country in which you are applying. However, in general, the steps for applying for a patent
                        typically include:</p>
                    <ul class="f-16" style={{ listStyle: 'circle', paddingInlineStart: 40 + 'px' }}>
                        <li>Conducting a patent search: Before applying for a patent, it’s important to search for existing
                            patents to ensure that your invention is new and non-obvious.</li>
                        <li>Preparing the application: This typically includes drafting the specification, which is a detailed
                            description of the invention, as well as any drawings or diagrams that may be necessary to
                            understand the invention. You’ll also need to prepare claims, which define the scope of the
                            invention.</li>
                        <li>Filing the application: Once the application is prepared, it must be filed with the appropriate
                            patent office. This typically includes paying a filing fee and submitting the application along with
                            any necessary documents.</li>
                        <li>Examination: After the application is filed, it will be examined by a patent examiner. The examiner
                            will determine if the application meets the necessary requirements for a patent.</li>
                        <li>Allowance or rejection: If the examiner determines that the application meets the necessary
                            requirements, they will issue a notice of allowance. If the application is rejected, the applicant
                            can respond by addressing the issues raised by the examiner or by appealing the decision.</li>
                        <li>Grant and Maintenance fee : Once the patent is granted, maintenance fees must be paid to keep the
                            patent in force.</li>
                        <li>Please note that this is a general overview of the process and the specifics may vary depending on
                            the country you are applying in. It’s also a good idea to consult a patent attorney or agent to
                            guide you through the process and ensure that your application is properly prepared.</li>
                    </ul>
                    <p class="f-16">Next we discuss the patenting process in depth and there is much to talk about.&nbsp; If you
                        want to short-cut the process, we have a <a href="provisional-builder" class="d-clr">free
                            provisional patent filing software</a> created to help inventors.</p>
                    <div class="f-16">In general, before you patent, you should conduct a patent search.&nbsp; <span
                        class="wordai-block rewrite-block enable-highlight" data-id="23">Patent searches are a process that
                        looks for patents in existence and publishes patent applications. This helps to determine whether an
                        invention is novel or not.</span>&nbsp;<span class="wordai-block rewrite-block enable-highlight"
                            data-id="29">This is a crucial step for inventors and businesses in order to make sure their ideas
                            don’t infringe existing patents, and to determine the market potential for their
                            invention.</span>&nbsp;<span class="wordai-block rewrite-block enable-highlight" data-id="13">Online
                                databases like the US Patent and Trademark Office and the European Patent Office (EPO) allow patent
                                searches to be made.</span></div>
                    <div>
                        <p class="f-16"><span class="wordai-block rewrite-block enable-highlight" data-id="2">Online patent can
                            be located for free.&nbsp;</span><button></button>You can conduct a patent search online using a
                            variety of free resources:</p>
                        <ol class="f-16">
                            <li><span class="wordai-block rewrite-block enable-highlight" data-id="9">Google Patents: Provides
                                access to millions patents from multiple countries including the US, Europe and
                                Japan.</span></li>
                            <li><span class="wordai-block rewrite-block enable-highlight" data-id="30">The US Patent and
                                Trademark Office allows you to view and search patents issued by USPTO. This includes
                                full-text and image searches of patents dating back as far as 1790.</span></li>
                            <li><span class="wordai-block rewrite-block enable-highlight" data-id="22">European Patent Office
                                (EPO), provides access to patent databases, documents and information from the EPO including
                                the European Patent Register.</span></li>
                            <li><span class="wordai-block rewrite-block enable-highlight" data-id="19">World Intellectual
                                Property Organization (WIPO), provides access to multiple patent databases, including the
                                Patent Cooperation Treaty search system.</span></li>
                            <li><span class="wordai-block rewrite-block enable-highlight" data-id="15">Patent pending: A free
                                resource that offers information about the patent process and a searchable database with
                                pending patent applications.</span></li>
                        </ol>
                    </div>
                    <div>
                        <h3>How to Get a Free Patent</h3>
                        <p class="f-16">A patent is a legal process that requires you to file a patent application with a
                            government agency such as the United States Patent and Trademark Office or the European Patent
                            Office. This process can be costly and requires the hiring of a patent attorney to prepare and file
                            the application.</p>
                        <p class="f-16">There is no free way to obtain a patent. You will need to pay government fees in order
                            to obtain a patent. These fees can vary depending on where you live and what type of patent.
                            Additional costs may be incurred for hiring a patent agent or attorney to help with the application
                            process.</p>
                        <p class="f-16">You may be able to obtain a patent without the expense. However, if you are unable to
                            pay for a full patent application, there are other options. These include a provisional petition
                            application that allows you to set a filing date and protect your intellectual property at a lower
                            price. For advice about the best way to handle your situation, it’s a good idea to speak with an
                            attorney or patent professional.</p>
                        <h3>Free help for inventors</h3>
                        <p class="f-16">There are many resources that can help inventors with their invention.</p>
                        <ul class="f-16" style={{ listStyle: 'circle', paddingInlineStart: 40 + 'px' }}>
                            <li>US Patent and Trademark Office: The USPTO provides free resources to inventors. This includes a
                                tutorial about the patent process, information regarding patent search, and a list listing
                                independent inventors’ organisations.</li>
                            <li>Small Business Administration (SBA). The SBA provides free resources to inventors and small
                                business owners, including information about starting and growing small businesses, as well as
                                access to training and counseling.</li>
                            <li>Public libraries: There are many resources available to inventors in public libraries, including
                                access databases of patent information and reference materials about the patent process.</li>
                            <li>Online forums: Many online discussion groups and forums are available for inventors and
                                entrepreneurs. Here you can find advice and support from other inventors.</li>
                            <li>Technology transfer offices at universities: Many universities have technology transfer office
                                that can offer support and resources for inventors and startups. They also have access to patent
                                databases and expert advice in commercializing technology.</li>
                        </ul>
                        <p class="f-16">These resources are valuable, but it is important to remember that they may not be able
                            to provide you with the right advice.&nbsp; Thus, after you have helped yourself with free patent
                            resources such as the ProvisionalBuilder(R) software from Inventiv, we recommend you seek help from
                            a pro-bono patent attorney.</p>
                        <h3>Pro bono patent attorney</h3>
                        <p class="f-16">Pro bono patent attorneys offer their services free of charge or at a reduced rate to
                            inventors who cannot afford the cost to obtain a patent.</p>
                        <p class="f-16">Many organizations offer pro bono assistance in patent matters to inventors.</p>
                        <ul class="f-16" style={{ listStyle: 'circle', paddingInlineStart: 40 + 'px' }}>
                            <li>Volunteer Lawyers for the Arts provides pro bono legal support to artists. This includes help
                                with trademark and patent issues.</li>
                            <li>The Legal Aid Society: Provides pro bono legal assistance to low-income people, including help
                                with trademark and patent issues.</li>
                            <li>Patent Pro Bono Program: This national program connects low-income inventors to volunteer patent
                                attorneys who can offer pro bono legal advice.</li>
                            <li>The Inventors Assistance Center is a division within the US Patent and Trademark Office. It
                                provides information and assistance for independent inventors.</li>
                        </ul>
                        <p class="f-16">Noting that pro bono attorneys are not always available in all areas, and that there
                            might be a waiting list for certain services, is important. Pro bono patent attorneys may be able to
                            provide valuable assistance and you should seek their advice about the best way to proceed in your
                            particular situation.</p>
                    </div>
                    <h3>ProvisionalBuilder™ is free software for preparing a high quality provisional patent application.&nbsp;
                        <a href="provisional-builder" class="d-clr"> Sign-up for your free access today</a>!</h3>

                    <p class="f-16 mt-3"><strong><em>Table of Content</em></strong></p>
                    <p class="f-16"><a href="patentfaqs/ip-basics" class="d-clr">1. Introduction to Inventiv</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}>&nbsp; &nbsp;<span style={{ color: '#808080' }}>1.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#ipbasics">IP Basics</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>1.1.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#copyright">Copyright</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>1.1.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#trademark">Trademark</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>1.1.3&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#tradesecret">Trade secret</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}><em>1.1.4</em>&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#unfaircompetition">Unfair
                        competition</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>1.1.5&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#patents">Patents</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>1.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/ip-basics#ipcategory">How do I determine which IP
                        category is best for me</a></span></p>
                    <p class="f-16"><a href="patentfaqs/patent-basics" class="d-clr">2. Patent Basics</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>2.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#legalauthority">What is the legal
                        authority behind patents?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>2.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#governmentagency">What government
                        agency is in charge of administering patents?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>2.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#patentadvantages">What are the
                        advantages of owning a patent?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>2.4&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#patentingfacrors">Factors to
                        consider before Patenting:</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/patent-basics#commercializable">Is the invention
                        commercializable?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#invention">Did I invent the
                        invention?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#own">Do I own the
                        invention?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.4&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#patentable">Is the invention
                        patentable?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.5&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#class">Does it fit into a patent
                        “class”?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.6&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#novel">Is it novel?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>2.4.7&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-basics#non-obvious">&nbsp;Is it
                        non-obvious?</a></span></p>
                    <p class="f-16"><a href="patentfaqs/set-up-an-account-with-the-uspto" class="d-clr">3. How to set up an
                        account with the USPTO?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>3.1&nbsp;&nbsp;&nbsp;&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/set-up-an-account-with-the-uspto#gettingstarted">Getting Started – New
                        Users</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>3.2&nbsp;&nbsp;&nbsp;&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/set-up-an-account-with-the-uspto#keyconsiderations">USPTO Registration
                        Modernizing – key considerations</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>3.3&nbsp;&nbsp;&nbsp;&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/set-up-an-account-with-the-uspto#filingavailable">Filing types available
                        at USPTO.gov Account</a></span></p>
                    <p class="f-16"><a href="patentfaqs/need-for-patent-attorney-or-agent" class="d-clr">4. Will I need a
                        patent attorney or agent for the application process?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>4.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/need-for-patent-attorney-or-agent#difference">What’s the difference
                        between a patent attorney and agent?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>4.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/need-for-patent-attorney-or-agent#registered">What
                        is the process to become a USPTO registered patent attorney or patent agent?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>4.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/need-for-patent-attorney-or-agent#paperwork">Is
                        there paperwork that an attorney may ask me to sign for his/her services?</a></span></p>
                    <p class="f-16"><a href="patentfaqs/provisional-patent-application" class="d-clr">5. What is a
                        provisional patent application (PPA)?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#ppa">What is
                        provisional patent application (PPA)?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#benefits">What are
                        the benefits of a provisional patent?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.3&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#drawbacks">What are
                        the drawbacks of a provisional patent?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.4&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#format">What is the
                        recommended format for a provisional patent application (PPA)?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#title">Title</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.2&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#abstract">Abstract</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.3&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#drawings">Drawings</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.4&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#description">Description</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.5&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#priorart">Prior
                        art</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.6&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#inventor">Inventor</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.7&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#multiple">Multiple
                        inventions</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.4.8&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#fees">Fees</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.5&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#ppaapplication">What should I think about
                        in drafting the PPA application?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.6&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#optional">What is
                        optional for a PPA?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.6.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#claims">Claims</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>5.6.2&nbsp; &nbsp;<a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-patent-application#prototype">Prototype</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>5.7&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-patent-application#ppafiling">What do
                        you do for improvements after PPA filing?</a></span></p>
                    <p class="f-16"><a href="patentfaqs/provisional-builder" class="d-clr">6. How can ProvisionalBuilder™
                        help with this process?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#provisionalbuilder">What are the benefits to using
                        ProvisionalBuilder™?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#work">How does
                        ProvisionalBuilder™ work?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#installed">What do I do after
                        ProvisionalBuilder™ is installed?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.4&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#start">How do I start an
                        application on ProvisionalBuilder?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.4.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#template">New
                        application—template selection</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.4.2&nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#overview"> “Overview”
                        page</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.4.3&nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#background"> “Background”
                        section</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.4.4&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#summary">“Summary”
                        section</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.4.5&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#description">“Detailed
                        Description” section</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.5 <a style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#drawing">“Drawing &amp; Description” page</a></span>
                    </p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#drawings">&nbsp;Drawings</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.2&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#newfigure">Adding a new
                        figure</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.3 <a style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#drawfting">Drafting the Detailed Description from the
                        Drawings</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.4 <a style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#suggestions">Suggestions for Drafting the Detailed
                        Description of the Drawing</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.5&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#coversheet">PPA Cover Sheet
                        page</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>6.5.6 <a style={{ color: '#808080' }}
                        href="patentfaqs/provisional-builder#generate">Generate application page</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.6&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#online">Filing
                        online</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>6.7&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/provisional-builder#usps">Filing by USPS Express
                        Mail</a></span></p>
                    <p class="f-16"><a href="patentfaqs/after-ppa-submission" class="d-clr">7. After you submit a PPA</a>
                    </p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>7.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/after-ppa-submission#uspto">What the USPTO
                        does?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>7.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/after-ppa-submission#you">What you can
                        do?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>7.2.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/after-ppa-submission#patentpending">May mark
                        product with “patent pending” or “patent applied”</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>7.2.2&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/after-ppa-submission#ppas">You can file Multiple
                        PPAs</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>7.2.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/after-ppa-submission#convert">Convert a PPA to a
                        utility patent application</a></span></p>
                    <p class="f-16"><a href="patentfaqs/utility-patent-application" class="d-clr">8. Utility Patent
                        Application</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>8.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#utilitypatentapplication">Preparatory steps
                        before drafting your Utility Patent Application?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.1.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#process">Document the invention
                        process.</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.1.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#search">Search for
                        prior art.</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.1.3 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#imperfections">Be aware of imperfections in the
                        search process</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>8.2 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#inventorship">Inventorship – what happens when
                        you have multiple inventors?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.2.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#fix">How to fix
                        improper inventorship?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.2.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#typos">How to correct
                        typos in the name?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>8.2.3&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#ownership">Ownership</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>8.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#protect">How to protect
                        your ownership if you hire consultants to work on your invention?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.3.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#consultant">Consultant’s Agreement</a></span>
                    </p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.3.2 <a style={{ color: '#808080' }}
                        href="patentfaqs/utility-patent-application#joa">Joint Ownership Agreement (JOA)</a></span>
                    </p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>8.4&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#employer">Does your
                        employer have rights in your invention?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/utility-patent-application#submission">If your
                        employer isn’t interested in your invention submission</a></span></p>
                    <p class="f-16"><a href="patentfaqs/utility-patent-application" class="d-clr">9. File a Utility Patent
                        Application</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#reuse">Can I reuse information from my
                        PPA or other applications?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/file-a-utility-patent-application#drafting">What
                        should I consider in drafting the utility application?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.3&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/file-a-utility-patent-application#writeclaims">How
                        do I write the claims?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.4&nbsp; &nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/file-a-utility-patent-application#filing">What
                        documents are needed in filing the utility application?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#specification">Specification</a></span>
                    </p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/file-a-utility-patent-application#ids">Information
                        Disclosure Statement (IDS)</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.3&nbsp;<a
                        style={{ color: '#808080' }} href="patentfaqs/file-a-utility-patent-application#declaration">
                        Inventor’s Oath or Declaration</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.4&nbsp; &nbsp;<a
                        style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#fees">Fees</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.5&nbsp; &nbsp;<a
                        style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#transmittalletter">Transmittal
                        letter</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.6&nbsp; &nbsp;<a
                        style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#drawings">Drawings</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>9.4.7&nbsp; &nbsp;<a
                        style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#optional">Optional</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.5 <a style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#publication">Can you request early
                        publication of your application?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>9.6 <a style={{ color: '#808080' }}
                        href="patentfaqs/file-a-utility-patent-application#secrecy">What is a secrecy
                        order</a></span></p>
                    <p class="f-16"><a href="patentfaqs/what-happens-after-filing-utility-application" class="d-clr">10.
                        What happens after the utility application is filed?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>10.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/what-happens-after-filing-utility-application#preexamination">How to
                        Respond to Formality/Pre-Examination Review?</a></span></p>
                    <p class="f-16"><a href="patentfaqs/respond-to-office-actions" class="d-clr">11. How to respond to
                        office actions?</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>11.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/respond-to-office-actions#oa">First Office Action (OA)</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.1.1&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#officeaction">How to
                        handle an office action</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.1.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#allowance">First office
                        action allowance (Ex parte Quayle)</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.1.3&nbsp; &nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#response">Amendment in
                        response to Office Action (OA)</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.1.4&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#examiner">How to request
                        an interview with the examiner?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.1.5&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#timely">What happens if
                        a response is not timely filed?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>11.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#finaloa">Second or Final
                        Office Action (OA)</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.2.1&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/respond-to-office-actions#noticeofallowance">Notice of
                        Allowance</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.2.2 <a style={{ color: '#808080' }}
                        href="patentfaqs/respond-to-office-actions#reconsideration">Reconsideration</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.2.3&nbsp; <a
                        style={{ color: '#808080' }}
                        href="patentfaqs/respond-to-office-actions#amendment">Amendment</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 120 + 'px' }}><span style={{ color: '#808080' }}>11.2.4&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/respond-to-office-actions#examination">Request to
                        continue the examination after a final office action</a></span></p>
                    <p class="f-16"><a href="patentfaqs/patent-issued" class="d-clr">12. The USPTO has issued your
                        patent.</a></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>12.1 <a style={{ color: '#808080' }}
                        href="patentfaqs/patent-issued#last">How long does a utility patent last?</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>12.2&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-issued#expiration">Patent
                        expiration</a></span></p>
                    <p class="f-16" style={{ paddingLeft: 40 + 'px' }}><span style={{ color: '#808080' }}>12.3&nbsp; <a
                        style={{ color: '#808080' }} href="patentfaqs/patent-issued#extensions">Extensions to the
                        Patent</a></span></p>

                </div>
            </section>
        </Layout>
    )
};

export default PatentFaqs;